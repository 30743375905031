import {
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DynamicModal from "components/modals/DynamicModal";
import React, { useEffect, useState } from "react";
import {
    Col,
    Row,
    Form,
    Button,
    Placeholder,
    Modal,
    Tooltip,
    OverlayTrigger,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
    forceSubmitAssignment,
    getSubmission,
    saveProgress,
    submitAssignment,
    terminatedAssignment,
} from "services/Student/Submission.services";

const AttemptSafeExercise = ({ padding }) => {
    const [exerciseInfo, setExerciseInfo] = useState(null);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [writtenResponses, setWrittenResponses] = useState({});
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [submissionId, setSubmissionId] = useState(null);
    const [timeRemaining, setTimeRemaining] = useState(null);
    const [showTimeUpModal, setShowTimeUpModal] = useState(false);
    const [fullView, setFullView] = useState(false);
    const [audioUrl, setAudioUrl] = useState("");

    const navigate = useNavigate();
    const params = useParams();
    const id = params._id;


    useEffect(() => {
        const fetchExerciseDetails = async () => {
            try {
                setLoading(true);
                const response = await getSubmission(id);
                const { exercise, answers, _id, endTime } = response?.data?.result;

                if (endTime) {
                    const endTimeMs = new Date(endTime).getTime();
                    const now = new Date().getTime();
                    const timeDiff = endTimeMs - now;

                    if (timeDiff > 0) {
                        setTimeRemaining(timeDiff);
                    } else {
                        setShowTimeUpModal(true);
                    }
                } else {
                    // No time limit, so we set `timeRemaining` to null
                    setTimeRemaining(null);
                }

                const prefilledAnswers = {};
                const prefilledWrittenResponses = {};

                answers.forEach((ans) => {
                    const question = exercise.questions.find(
                        (q) => q._id === ans.question
                    );
                    if (question) {
                        if (
                            question.type === "multiple-choice" ||
                            question.type === "true-false"
                        ) {
                            prefilledAnswers[ans.question] = ans.answer;
                        } else if (
                            question.type === "fill-in-the-blank" ||
                            question.type === "written-answer"
                        ) {
                            prefilledWrittenResponses[ans.question] = ans.answer;
                        }
                    }
                });

                setSelectedAnswers(prefilledAnswers);
                setWrittenResponses(prefilledWrittenResponses);
                setExerciseInfo(exercise);
                setSubmissionId(_id);
                setAudioUrl(exercise?.audioUrl?.url);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching Exercise details:", error);
            }
        };

        fetchExerciseDetails();
    }, [id]);

    useEffect(() => {
        if (timeRemaining && timeRemaining > 0) {
            const intervalId = setInterval(() => {
                setTimeRemaining((prev) => {
                    if (prev <= 1000) {
                        clearInterval(intervalId);
                        forceSubmitAssignment(submissionId)
                            .then(() => {
                                toast.success("Time's up! Assignment has been submitted.");
                                setShowTimeUpModal(true);
                            })
                            .catch((error) => {
                                toast.error("Error submitting assignment after timeout.");
                                console.error(error);
                            });
                        return 0;
                    }
                    return prev - 1000;
                });
            }, 1000);

            return () => clearInterval(intervalId);
        }
    }, [timeRemaining]);

    const handleOptionChange = (questionId, selectedOption) => {
        setSelectedAnswers({
            ...selectedAnswers,
            [questionId]: selectedOption,
        });
    };

    const handleTextAreaChange = (questionId, value) => {
        setWrittenResponses({
            ...writtenResponses,
            [questionId]: value,
        });
        console.log("written re", writtenResponses);
    };

    const handleSaveProgress = async () => {
        setSaving(true);
        const combinedAnswers = { ...selectedAnswers, ...writtenResponses };

        const payload = {
            submissionId,
            answers: Object.keys(combinedAnswers).map((questionId) => ({
                questionId,
                answer: combinedAnswers[questionId], // Combine both selected and written answers
            })),
        };

        try {
            await saveProgress(payload);
            toast.success("Progress saved successfully!");
            setSaving(false);
        } catch (error) {
            toast.error("Error saving progress, please try again.");
            console.error("Error saving progress:", error);
            setSaving(false);
        }
    };

    const handleSubmitAssignment = async () => {
        setSaving(true);
        const combinedAnswers = { ...selectedAnswers, ...writtenResponses };

        const allQuestionsAnswered = exerciseInfo?.questions.every(
            (q) => combinedAnswers[q._id]
        );

        if (!allQuestionsAnswered) {
            toast.error("Please answer all questions before submitting.");
            return;
        }

        const payload = {
            submissionId,
            answers: Object.keys(combinedAnswers).map((questionId) => ({
                questionId,
                answer: combinedAnswers[questionId],
            })),
        };

        try {
            await submitAssignment(payload);
            toast.success("Assignment submitted successfully!");
            setSaving(false);
            navigate("/thanks");
        } catch (error) {
            toast.error("Error submitting assignment, please try again.");
            console.error("Error submitting assignment:", error);
            setSaving(false);
        }
    };

    const formatTime = (ms) => {
        const totalSeconds = Math.floor(ms / 1000);
        const hours = String(Math.floor(totalSeconds / 3700)).padStart(2, "0");
        const minutes = String(Math.floor((totalSeconds % 3700) / 70)).padStart(
            2,
            "0"
        );
        const seconds = String(totalSeconds % 70).padStart(2, "0");
        return `${hours}:${minutes}:${seconds}`;
    };

    const handleView = () => {
        setFullView(!fullView);
    };

    const isWritingExercise = exerciseInfo?.exerciseType === "writing";
    const isReadingExercise = exerciseInfo?.exerciseType === "reading";
    const isListeningExercise = exerciseInfo?.exerciseType === "listening";


    const highlightPassage = (passage, words) => {
        if (!passage || !words) return passage;

        const wordList = words.map((word) => word.title);
        const regex = new RegExp(`\\b(${wordList.join("|")})\\b`, "gi");

        const highlightedPassage = passage.split(regex).map((part, index) => {
            const matchedWord = words.find(
                (word) => word.title.toLowerCase() === part.toLowerCase()
            );

            if (matchedWord) {
                return (
                    <span
                        key={index}
                        className="highlighted-word"
                        title={`Meaning: ${matchedWord.meaning.join(", ")} | Sentence: ${matchedWord.sentence}`}
                    >
                        {part}
                    </span>
                );
            }
            return part;
        });

        return highlightedPassage;
    };

    return (
        <div className={`${padding ? 'p-4' : ''}`}>
            <div>
                {exerciseInfo ? (
                    <div className="d-flex justify-content-between">
                        <div>
                            <h3>{exerciseInfo.title}</h3>
                        </div>
                        {timeRemaining !== null ? (
                            <div className="timer mt-2 me-2">
                                Time remaining:{" "}
                                <span className="text-danger">{formatTime(timeRemaining)}</span>
                            </div>
                        ) : (
                            <div className="timer mt-2 me-2">
                                <span className="text-success"></span>
                            </div>
                        )}
                    </div>
                ) : (
                    <Placeholder as="div" animation="glow">
                        <Placeholder xs={3} className="mb-2" />
                        <div>
                            <Placeholder xs={6} />
                        </div>
                    </Placeholder>
                )}
            </div>

            <div
                style={{
                    height: "75vh",
                    border: "1px solid #A7A7A7",
                    borderRadius: "30px",
                    padding: "30px",
                }}
            >
                <Row className="h-100">
                    {isReadingExercise && (
                        <Col
                            xs={fullView ? 12 : 6}
                            style={{
                                borderRight: fullView ? "none" : "2px solid #8A8A8A",
                                height: "52vh",
                            }}
                        >
                            {exerciseInfo ? (
                                <>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <h3 className="text-capitalize">Comprehension Passage</h3>
                                        </div>
                                        <div>
                                            <Button
                                                onClick={() => handleView()}
                                                className="button-style border-0"
                                            >
                                                <FontAwesomeIcon
                                                    icon={fullView ? faChevronLeft : faChevronRight}
                                                />
                                                {fullView && (
                                                    <span className="ms-2">Show Questions</span>
                                                )}
                                            </Button>
                                        </div>
                                    </div>

                                    <div
                                        className="custom-scrollbar"
                                        style={{
                                            height: "90%",
                                            overflow: "auto",
                                        }}
                                    >
                                        <div>{highlightPassage(exerciseInfo?.passage, exerciseInfo?.words)}</div>
                                    </div>
                                </>
                            ) : (
                                <Placeholder as="div" animation="glow">
                                    <Placeholder xs={6} className="mb-2" />
                                    <Placeholder xs={8} />
                                    <Placeholder xs={4} />
                                    <Placeholder xs={7} />
                                </Placeholder>
                            )}
                        </Col>
                    )}

                    {isListeningExercise && (
                        <Col
                            xs={6}
                            className="custom-scrollbar"
                            style={{
                                height: "70vh",
                                overflow: "auto",
                                borderRight: "2px solid #8A8A8A",
                            }}
                        >
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                {/* <h4>{exerciseInfo.title}</h4>
                    <span className={`bg-primary px-3 mx-2 rounded-4 text-white`}>
                      {exerciseInfo.status}
                    </span> */}
                                <h5>
                                    Listen carefully to the audio provided. After listening,
                                    please answer the following questions based on the content of
                                    the audio.
                                </h5>
                            </div>
                            <div className="">
                                <audio controls src={audioUrl} className="mt-5 w-100" />
                            </div>
                        </Col>
                    )}

                    <Col
                        xs={isWritingExercise ? 12 : fullView ? 0 : 6}
                        className={`${fullView ? "d-none" : "d-block"} custom-scrollbar`}
                        style={{ height: "70vh", overflow: "auto" }}
                    >
                        {exerciseInfo ? (
                            exerciseInfo.questions.map((question, index) => (
                                <div key={question._id} style={{ marginBottom: "20px" }}>
                                    <h5>
                                        {`Question ${index + 1}: ${question.questionText}`} (
                                        {question.score} points)
                                    </h5>

                                    {question.type === "multiple-choice" && (
                                        <Form>
                                            {question.options.map((option, i) => (
                                                <Form.Check
                                                    type="radio"
                                                    label={option}
                                                    name={`question-${question._id}`}
                                                    key={i}
                                                    value={option}
                                                    checked={selectedAnswers[question._id] === option}
                                                    onChange={() =>
                                                        handleOptionChange(question._id, option)
                                                    }
                                                />
                                            ))}
                                        </Form>
                                    )}

                                    {question.type === "true-false" && (
                                        <Form>
                                            <Form.Check
                                                type="radio"
                                                label="True"
                                                name={`question-${question._id}`}
                                                value="True"
                                                checked={selectedAnswers[question._id] === "True"}
                                                onChange={() =>
                                                    handleOptionChange(question._id, "True")
                                                }
                                            />
                                            <Form.Check
                                                type="radio"
                                                label="False"
                                                name={`question-${question._id}`}
                                                value="False"
                                                checked={selectedAnswers[question._id] === "False"}
                                                onChange={() =>
                                                    handleOptionChange(question._id, "False")
                                                }
                                            />
                                        </Form>
                                    )}

                                    {["written-answer", "fill-in-the-blank"].includes(
                                        question.type
                                    ) && (
                                            <Form.Group>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={4}
                                                    value={writtenResponses[question._id] || ""}
                                                    onChange={(e) =>
                                                        handleTextAreaChange(question._id, e.target.value)
                                                    }
                                                    placeholder="Write your answer here..."
                                                />
                                            </Form.Group>
                                        )}
                                </div>
                            ))
                        ) : (
                            <Placeholder as="div" animation="glow">
                                <Placeholder xs={8} className="mb-2" />
                                <Placeholder xs={12} />
                                <Placeholder xs={10} />
                            </Placeholder>
                        )}
                    </Col>
                </Row>
            </div>

            <div className="mt-2 d-flex justify-content-end">
                <div className="d-flex gap-3">
                    <Button
                        variant="secondary"
                        disabled={saving}
                        className="mt-1 px-4 py-2 border-0 button-style"
                        onClick={handleSaveProgress}
                    >
                        Save Progress
                    </Button>
                    <Button
                        variant="primary"
                        className="mt-1 px-4 py-2 border-0 button-style"
                        onClick={handleSubmitAssignment}
                        disabled={
                            !exerciseInfo?.questions.every(
                                (q) => selectedAnswers[q._id] || writtenResponses[q._id]
                            ) || saving
                        }
                    >
                        Submit Assignment
                    </Button>
                </div>
            </div>

            {/* <Modal show={showTimeUpModal} onHide={() => navigate("/assignments")}>
          <Modal.Header closeButton>
            <Modal.Title>Time's Up!</Modal.Title>
          </Modal.Header>
          <Modal.Body>Your time for this exercise has expired.</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => navigate("/assignments")}>
              Back to Assignments
            </Button>
          </Modal.Footer>
        </Modal> */}
        </div>
    );
};

export default AttemptSafeExercise;
