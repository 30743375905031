import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Submissions from './Submissions'
import ViewSubmissions from './ViewSubmissions'
import MarkSubmission from './MarkSubmission'

const SubmissionsRoutes = () => {
    return (
        <>
            <Routes>
                <Route
                    path='/:id'
                    element={<ViewSubmissions />}
                />
                <Route
                    path='/view-all/:exerciseId'
                    element={<Submissions />}
                />
                <Route
                    path='/mark/:_id'
                    element={<MarkSubmission />}
                />



            </Routes>
        </>
    )
}

export default SubmissionsRoutes